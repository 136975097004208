/**
 * Featured Articles: 
 * DBS>Interactive
 */
.partial-featured-articles {
	margin-bottom: 0 !important;
	margin-top: 0 !important;

	.contain {position: relative;}

	.latest-articles__heading {
		position: absolute;
			top: 3rem;
		z-index: 2;

		a.eyebrow {
			margin-left: $spacing;
			text-decoration: none;

			&:hover,
			&:focus {text-decoration: underline;}
		}
	}

	.blog-main {display: flex;}

	.post {
		width: 90%;
		
		&__wrap {
			display:block;
			padding: 8rem 1rem 6rem 0;
			position: relative;

			&:after {
				background: linear-gradient(to bottom, white, #e5e5e5);
				height: 100%;
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 2px;
			}
		}

		&:last-child .post__wrap:after {content: none;}

		&__image {display: none;}

		&__image {display: none;}

		&__header {
			background-color: $blueDarkest;
			margin-bottom: 1.5rem;

			&-month {
				color: $green;
				padding: 1.5rem 1.5rem 3rem 1.5rem;
				font-weight: bold;
				letter-spacing: 1px;
			}

			&-day {
				font-size: 6.5rem;
				font-weight: 900;
				color: $white;
				line-height: .7;
				padding: 0 1.5rem;
			}
		}

		.arrow-link.boxed {padding-right: 3rem;}
	}

	@include media($screen-sm) {
		.post {width: 45%;}
	}

   @include media($screen-md-max, 'max') {
		.blog-main {
			flex-wrap: nowrap;
			overflow: auto;
			width: calc(100% + 1.5rem);
	   	}

	   .post {
			flex: 0 0 auto;
			margin-right: 1rem;
		}
	}

	@include media($screen-md) {

		.blog-main {margin-right: -2rem;}

		.post {
			border: 0;
			margin-right: 1rem;

			&__header {
				&-day {font-size: 7.875rem;}
			}

			&__wrap {
				border-left: 0;
				display: block;
				padding-right: 1rem;
			}
		}
	}
}