.services {

	&__main {
		margin-bottom: $spacing;

		&-image {
			height: 0;
			overflow: hidden;
			padding-top: 85%;
			position: relative;

			img {@include object-fit(cover, top center);}
		}
	}

	&__cta {

		&-text {
			padding: #{$spacing*2} $spacing;

			.arrow-link {
				display: inline-block;
				margin: 2rem 0 0;
				min-width: 12rem;
			}
		}
	}

	@include media($screen-sm-max, 'max') {
		&__main {

			&-image {
				margin: 0 -#{$spacing} #{$spacing};
			}
		}

		&__cta {
			margin: 0 -#{$spacing};
		}
	}

	@include media($screen-sm) {
		&__main {
			&-image {
				padding-top: 50%;
				width: 40%;

				img {object-position: center;}
			}
	
			&-text {
				padding: 3rem 0 3rem 3rem;
				width: 60%;
			}
		}
	
		&__cta {
			padding: 0 5rem !important;

			&-image {
				align-self: stretch;
				margin-right: 8px;
				overflow: hidden;
				position: relative;

				img {@include object-fit(cover, center);}
			}
		}
	}

	@include media($screen-md) {
		&__main {
			margin-bottom: 0;
			
			&-text {padding-left: 4.5rem;}
		}

		&__cta {
			margin-top: -4.5rem;
			position: relative;
			z-index: 2;

			&-text {
				padding: $spacing*2;

				.arrow-link {margin: 1.5rem 0 0 3rem;}
			}
		}
	}
}

// When placed after the home hero...
.home-hero + .services {
	@include media($screen-sm-max, 'max') {
		margin-top: 0;
	}

	background: white;
	position: relative;
	z-index: 2;
}