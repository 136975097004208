/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

.site-footer {
	overflow: hidden;
	clear: both;
	margin-bottom: 0 !important;
	margin-top: auto !important; // Sticky footer

	.contain {
		@include default-padding;
	}

	&__container {
		padding: 1em $spacing;
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a {
		color: $white;
		font-weight: 400;
		text-decoration: none;
	}

	&__logo {
		display: inline-block;

		svg {
			display: block;
			max-width: 10em;
		}
	}

	&__subscribe {
		padding-bottom: $spacing !important;
		padding-top: $spacing !important;

		.arrow-link {
			display: inline-block;
			margin-top: 1rem;
		}
	}

	&__main {
		border-bottom: 1px solid rgba($white,.75);
		border-top: 1px solid rgba($white,.75);
		padding: #{$spacing*2} 0;
	}

	&__meta {
		.arrow-link {
			font-size: 1.25rem;
			font-weight: 400;
			letter-spacing: normal;
			text-transform: none;
		}
	}

	&__navigation {
		margin-top: $spacing;
		text-decoration: none;

		.footer-menu__item {
			a {
				display: block;
				padding: .25rem 0;

				&:after {
					background: linear-gradient(to right, #00DF7B, #0C863D);
					content: '';
					display: block;
					height: 1px;
					position: relative;
						top: 3px;
					transform: scaleX(0);
					transform-origin: 100% 0;
					transition: transform .25s ease-in-out;
				}
	
				&:hover,
				&:focus {
					text-decoration: none;

					&:after {
						transform: scaleX(1);
						transform-origin: 0 100%;
					}
				}
			}

			&.toplevel {
				margin-bottom: 1rem;

				& > a {font-weight: 900; letter-spacing: .5px;}
			}
		}

		li { display: block; }
	}

	.social-media {
		margin: $spacing 0 0;

		&__link {
			display: inline-block;
			margin-right: 1rem;
		}

		a {
			display: block;
			width: 1.75rem;

			&:hover,
			&:focus {
				svg path {fill: $green;}
			}
		}

		svg {
			display: block;
			
			path {
				fill: $white;
				transition: .25s ease-in-out;
			}
		}
	}

	&__credits {
		font-size: .9rem;
		padding: $spacing 0;
		width: 100%;

		.legal-footer {
			display: inline-block;

			ul, li {display: inline-block;}

			.legal-menu__item {
				border-right: 1px solid $white;
				margin-right: .5rem;
				padding-right: .75rem;

				&:last-child {border: none;}

				a:hover, a:focus {text-decoration: underline;}
			}
		}
	}

	@include media($screen-sm-max, 'max') {
		&__contact-info {
			margin-bottom: 1em;
		}
		
		&__logo {margin-bottom: $spacing;}

		&__navigation {
			.footer-menu__submenu {display: none;}

			.footer-menu__item {
				&.toplevel {
					> a {
						font-weight: 900;
						text-transform: uppercase;
					}
				}
				
				&--parent {
					position: relative;

					&.open {
						.footer-menu__submenu {display: block;}
					}
				}
			}

			.submenu-toggle {top: -.75rem;}
		}

		.legal-footer {margin-top: .5rem;}
		#dbs-chev {margin-top: $spacing;}
	}

	@include media($screen-sm) {
		&__hours {
			margin-left: 1.5em;
			text-align: center;
		}
		
		&__subscribe {
			.arrow-link {
				margin: 0 0 0 3rem;
			}
		}

		.social-media {
			margin-top: $spacing*2;
		}

		&__meta-links {
			margin-top: $spacing;

			.arrow-link {
				&:after {background-image: url('#{$libraryPath}/icons/src/arrow-right-white.svg');}
				&:last-child {margin-left: 2rem;}
			}
		}

		&__navigation {
			.submenu-toggle {display: none;}
		}

		.legal-footer {margin-left: 1rem;}
		#dbs-chev {margin-left: auto;}
	}

	@include media($screen-md) {
		&__navigation {
			flex: 1;
			padding-left: 4.5rem;

			> ul {
				display: flex;
				justify-content: space-between;
			}
		}
	}
}
