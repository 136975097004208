/**
 * These are mostly plug-in provided styles. Their stylesheet has be dequeued and
 * they are included here so we have more control over it.
 */
.searchwp-live-search-results {
	box-shadow: 0 0 2px 0 rgba(30,30,30,0.4);
	box-sizing: border-box;
	border-radius: 3px;
	width: 315px;
	min-height: 175px;
	max-height: 300px;
}

.searchwp-live-search-result p {
	font-size:0.9em;
	padding:1em;
	margin:0;
	border-bottom:1px solid rgba(30,30,30,0.1);
}

.searchwp-live-search-result--focused {
	background: #DDD;
}

.searchwp-live-search-result--focused a {
	color: #000;
}

.searchwp-live-search-result:last-of-type p {
	border-bottom:0;
}

.searchwp-live-search-result a {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Bullet proof Screen Reader Only text */
.searchwp-live-search-instructions {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}


@keyframes searchwp-spinner-line-fade-more {
  0%, 100% {
    opacity: 0; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}

@keyframes searchwp-spinner-line-fade-quick {
  0%, 39%, 100% {
    opacity: 0.25; /* minimum opacity */
  }
  40% {
    opacity: 1;
  }
}

@keyframes searchwp-spinner-line-fade-default {
  0%, 100% {
    opacity: 0.22; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}


// Styling for smart search plugin
.searchwp-live-search-results {
	background: $blueDarkest;
	border-top: 1px solid $green;
	border-radius: 0;
	color: $white;
	transform: translateY(-5px);

	a {
		color: $white;
		text-decoration: none;

		&:hover,
		&:focus {text-decoration: underline;}
	}

	p {
		border-bottom: 1px solid rgba($white,0.25);
		padding: .75rem 1.5rem;
	}

	.spinner {opacity: .7;}
}

.searchwp-live-search-no-min-chars:after {
	font-size: .9rem;
	font-weight: 300;
	padding: 1.5rem 2rem 0;
}

.blog-smart-search {
	height: 100%;
	position: absolute;
		top: 100%;
		left: 0;
	width: 100%;

	.searchwp-live-search-results {
		background: $white;
		color: $textColor !important;
		top: 5px !important;
		left: 0 !important;
		width: 100% !important;

		a {color: $textColor !important;}

		p {border-color: $grayLighter;}

		@include media($screen-sm-max, 'max') {
			border-bottom: 1px solid $green;
			top: 109% !important;
		}
	}
}