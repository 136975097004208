/**
 * Half and half flex layout
 * DBS>Interactive
 */

.half-and-half {
	&__left,
	&__right {
		@include grid(6);  // 2 in a row 6/12 each
	}

	// Mobile styles
	@include media ($screen-sm-max, 'max') {
		flex-wrap: wrap;
		&__left,
		&__right {
			padding-bottom: 0;
			order: 1;
			width: 100%;

			// Put halves with images before those without
			&.bg-img {
				height: 0;
				order: 0;
				overflow: hidden;
				padding-bottom: 82%;
			}

			&:not(.bg-img) .cell {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}


	@include media ($screen-sm) {
		/**
		 * This little bit of hackery makes the edge of background images and
		 * background colors line up with the container content when a contain
		 * class is on the half and half layout, but not when the
		 * 'contain-full-width' reset class is used.
		 */
		&[class*=contain]:not([class~=contain-full-width]) [class*=bg-] {
			width: calc( 50% - #{$spacing} );

			&.half-and-half__left { margin-left: $spacing; }
			&.half-and-half__right { margin-right: $spacing; }
		}

		&__left,
		&__right {
			&.bg-img {
				min-height: 20rem;
			}

			.cell {padding-bottom: 0;}
		}

		&__left .cell {padding-left: 0;}
		&__right .cell {padding-right: 0;}
	}

	@include media($screen-md) {
		&__left .cell {padding-right: $spacing*2 !important;}
		&__right .cell {padding-left: $spacing*2 !important;}
	}

	// Optional CMS class to get image to overflow container to the image's near side
	&.overflow-image {
		.bg-img img {
			object-fit: contain;
			object-position: right;
		}

		@include media($screen-sm-max, 'max') {
			.bg-img {
				margin-bottom: -3rem;

				&.half-and-half__right {margin-left: $spacing;}
				&.half-and-half__left {margin-left: -$spacing;}

				&.fade-image .image-wrapper {
					-webkit-mask-image: linear-gradient(to bottom, rgba($white, 1) 0%, rgba($white, 0) 100%);
				}
			}
		}
		
		@include media($screen-sm) {
			padding-bottom: $spacing*4;
			padding-top: $spacing*4;
			position: relative;

			.bg-img {
				position: static;

				&.half-and-half__right .image-wrapper {
					left: auto;
					right: 0;

					img {object-position: 3rem center;}
				}

				&.half-and-half__left .image-wrapper {
					img {object-position: -3rem center;}
				}

				.image-wrapper {
					height: calc(100% - 3rem);
					position: absolute;
						top: 50%;
					transform: translateY(-50%);
					width: 50%;
				}
			}
		}
	}

	// Optional CMS class to get image to overflow container vertically, 
	// and to the image's nearest side
	&.vertical-overflow {
		@include media($screen-sm) {
			margin-bottom: $spacing*6;
			margin-top: $spacing*6;
			position: relative;

			.bg-img {
				overflow: visible;
				position: relative;

				&.half-and-half__right .image-wrapper {
					left: auto;
					right: 0;

					img {object-position: center;}
				}

				&.half-and-half__left .image-wrapper {
					img {object-position: center;}
				}

				.image-wrapper {
					height: calc(100% + #{$spacing*12});
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}


	&.has-background {
		position: relative;

		.half-and-half__background {
			height: 50%;
			opacity: .8;
			position: absolute;
				bottom: 0;
				right: 0;
			width: 100%;

			img {@include object-fit(cover, center);}
		}

		@include media($screen-sm) {
			padding-bottom: 9rem !important;
			padding-top: 9rem !important;

			.half-and-half__background {
				height: 100%;
				width: 80%;

				img {object-position: left bottom;}
			}
		}
	}

	@include media($screen-sm) {
		&.two-thirds-left {
			.half-and-half__left {width: 60%;}
			.half-and-half__right {width: 40%;}
		}
	
	
		&.two-thirds-right {
			.half-and-half__left {width: 40%;}
			.half-and-half__right {width: 60%;}
		}		
	}
}